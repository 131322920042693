@import "../constatnt/fonts.scss";
@import "../constatnt/color.scss";

body,
html {
  font-family: "f1";
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}

body {
  background-color: $lt-blue2 !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(230, 230, 230);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
}

.out-left {
  z-index: 0;
}

.dashboard {
  width: 100%;
  display: flex;
  height: 100vh;
  overflow: hidden !important;

  .navBar {
    width: 276px;
    display: block;
    min-height: 100vh;
    overflow-x: scroll;
    transition: 0.3s ease;
    // padding-bottom: 30px;
    justify-content: center;
    box-shadow: rgba(189, 189, 190, 0.25) 0px 0px 0px 0px inset,
      rgba(164, 163, 163, 0.3) 0px 0px 36px 0px inset;
  }

  .dashRightView {
    height: 100vh;
    // width: 82%;
    background-color: white;
    padding: 50px;
    overflow: scroll;
    // margin-left: 276px !important;
  }

  .dashRightView_enterprises {
    // width: 82%;
    background-color: white;
    padding: 50px;
    // margin-left: 276px !important;
  }

  // .dashRightView {
  //   height: 100vh;
  //   width: 100%;
  //   background-color: white;
  //   padding: 50px;
  //   overflow: scroll;
  //   // margin-left: 276px !important;
  // }
  .dashRightView2 {
    position: relative;
    transition: 0.3s ease;
    top: 116px;
    right: 0;
    left: 0;
    height: calc(100% - 87px);
    width: calc(100% - 276px);
    background-color: white;
    padding: 50px 20px;
    overflow: scroll;
  }
}

.searchIcon {
  height: 25px;
  width: 25px;
}

.bgColorView1 {
  height: 75px;
  width: 10px;
  background-color: #ecf1ff;
  border-radius: 10px;
  position: relative;
  margin-right: 4px;
}

.bgColorView3 {
  width: 10px;
  border-radius: 10px;
  bottom: 0px;
  position: absolute;
  background-color: #1be6df;
}

.bgColorView2 {
  // height: 50px;
  width: 10px;
  border-radius: 10px;
  bottom: 0px;
  position: absolute;
  background: linear-gradient(#1be6df, #bff9f7);
}

.dailyStatus {
  border: white 1px solid;
}

.searchField {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  margin-bottom: 40px;
  padding: 20px 15px;
}

.searchFieldShade {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  margin-bottom: 40px;
  // padding: 20px 15px;
}

.dotColor {
  height: 5px;
  width: 5px;
}

.imgDashboard {
  width: 75px;
  height: 55px;
}

.searchManImg {
  width: 30px;
  height: 25px;
}

.searchManImgMan {
  width: 25px;
  height: 25px;
  top: 8px;
  left: 10px;
}

.searchiConImg {
  width: 23px;
  height: 23px;
  margin-top: 8px;
}

.buttnDesign {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.wholeDesign {
  width: 1000px;
}

.imgDashboard1 {
  width: 55px;
  height: 40px;
}

.commonSpaceImage {
  margin-left: 50px;
}

.commonSpaceImage1 {
  margin-left: 0px;
}

.commonSpaceName {
  margin-left: 100px;
}

.commonSpaceName1 {
  margin-left: 70px;
}

.commonSpacemail {
  margin-left: 140px;
}

.commonStatus {
  margin-left: 135px;
}

.commonSpaceOptions {
  margin-left: 125px;
}

.commonSpacemail1 {
  margin-left: 100px;
}

.adminCreatebtn {
  display: flex;
  justify-content: flex-end;
}

.reciSpace {
  margin-left: 15px;
}

.paymentSpace {
  margin-left: 25px;
}

.proImg {
  height: 150px;
  width: 150px;
}

.editBtn {
  border: $primary 1px solid;
}

.editBtnSelect {
  border: $primary 1px solid;
  padding: 10px;
}

.wholeDesignDash {
  width: 1090px;
}

.wholeCustomerDet {
  width: 1000px;
}

.wholeDesignDash1 {
  width: 1080px;
}

.wholeDesignCustomerDeco {
  width: 1100px;
}

.wholeDesignShippingAdd {
  width: 1050px;
}

.wholeDesignposBills {
  width: 1350px;
}

.dashTotalDesChart {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 38px 15px;
}

.dashTotalDesMonths {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 20px 15px;
}

// .viewBoxDes {
//   box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
//     inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
//     4px 5px 6px 1px rgba(149, 192, 234, 0.4);
//   -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
//     inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
//     4px 5px 6px 1px rgba(149, 192, 234, 0.4);
// }

.viewBoxDes {
  box-shadow: -5px -5px 15px 0px #fff, 5px 5px 15px 0px rgba(174, 174, 192, 0.4);
}

.viewBoxDesSign {
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  position: absolute;
  top: 20%;
  right: 38%;
}

.viewBoxDesSign1 {
  box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  -webkit-box-shadow: inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
    4px 5px 6px 1px rgba(149, 192, 234, 0.4);
  position: absolute;
  top: 30%;
  right: 38%;
}

.totalSiteDesWid {
  width: 70%;
}

.commonSpacemailROle {
  margin-left: 250px;
}

.commonSpacemailStatus {
  margin-left: 280px;
}

.commonSpacemailOptions {
  margin-left: 220px;
}

.addbtn {
  -webkit-box-shadow: inset -10px -10px 8px -1px $primary,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  padding: 9px 25px 9px 25px !important;
}

.addbtn_rel {
  -webkit-box-shadow: inset -10px -10px 8px -1px $primary,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  padding: 10px !important;
}

.cancelbtn {
  -webkit-box-shadow: inset -10px -10px 8px -1px #171717,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  padding: 9px 25px 9px 25px !important;
}

.orangebtn {
  -webkit-box-shadow: inset -10px -10px 8px -1px rgb(213, 139, 3),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  padding: 9px 25px 9px 25px !important;
}

.addbtn_cancel {
  -webkit-box-shadow: inset -10px -10px 8px -1px red,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  padding: 9px 25px 9px 25px !important;
}

.addbtn1 {
  -webkit-box-shadow: inset -10px -10px 8px -1px $primary,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  // padding: 9px 2px 9px 25px !important;
  width: 32px;
  height: 32px;
}

.eyeiConDesign {
  padding: 3px 5px 3px 5px;
  background-color: white;
  border-radius: 10px;
  border: 0;
  box-shadow: inset -5px -15px 20px 1px $Blue1, inset -7px -7px 3px 0px #fff,
    inset -5px -5px 9px 5px #fff, inset -5px -5px 9px 1px $icon-blue;
}

.eyeiConDesign1 {
  background-color: white;
  border-radius: 10px;
  border: 0;
  box-shadow: inset -5px -15px 20px 1px $orange, inset -7px -7px 3px 0px #fff,
    inset -5px -5px 9px 5px #fff, inset -5px -5px 9px 1px $orange;
}

.supp-select .css-13cymwt-control {
  border: 1px solid #196c9f !important;
  border-radius: 8px !important;
}

.normalInput {
  border: $primary 1px solid;
  padding: 0px 0px 0px 40px;
}

.checkIcon {
  background-color: $primary;
  color: white;
}

.checkIcon1 {
  background-color: white;
  color: white;
  border: $primary1 1px solid;
}

.viewIconDes {
  width: 20px;
  height: 20px;
}

.toggleOnDes {
  width: 35px;
  height: 20px;
}

.toggleOnDes1 {
  width: 25px;
  height: 15px;
}

.viewDes {
  width: 20px;
  height: 16px;
}

.signInDes {
  width: 100%;
  height: 100vh;
}

.dashTotalDesTotalSites {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 15px 23px 15px 23px;
  align-items: center;
  justify-content: center;
  display: grid;
}

.dashTotalDesTotalSites1 {
  border: $soft-gray1 1px solid;
  border-radius: 10px;
  background: #f1f7fc;
  box-shadow: 7px 7px 15px -1px #d1d6eb;
  -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 15px 23px 15px 23px;
  align-items: center;
  justify-content: center;
  display: grid;
}

.dashIcons {
  padding: 5px 90px 5px 90px;
}

.forgotPassword {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.visibleDes {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.signLeftDes {
  margin: 190px 0px 0px 100px;
  background-image: url("../../img/login_vector.webp");
  background-size: contain;
  background-repeat: no-repeat;
  height: 70%;
  width: 70%;
}

.vendorSpace {
  margin-left: 30px;
}

.dashImgDesig {
  margin-left: 100px;
}

.chartSpace {
  margin-right: 30px;
}

.pointerBtn {
  cursor: pointer;
  padding: 0px 20px 0px 60px;
}

.noDataImg {
  width: 90px;
  height: 70px;
}

.legalSpace {
  margin-left: 30px;
}

.primName {
  margin-left: 80px;
}

.vendorOffer {
  margin-left: 115px;
}

.vendorNum {
  margin-left: 110px;
}

.vendorStatus {
  margin-left: 60px;
}

.vendorOptions {
  margin-left: 70px;
}

.decorSpace {
  margin-left: 30px;
}

.poSpace {
  margin-left: 20px;
}

.soSpace {
  margin-left: 40px;
}

.qtySpace {
  margin-left: 50px;
}

.valueSpace {
  margin-left: 40px;
}

.StatusProdSpace {
  margin-left: 60px;
}

.radiusVendDes {
  border-radius: 50px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.vert-top {
  vertical-align: top !important;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

.profileImg {
  width: 180px;
  height: 180px;
  object-fit: contain;
  border-radius: 20px;
}

.vendorDetSpace {
  margin-top: 80px !important;
}

.replyImg {
  width: 80px;
  height: 80px;
}

.linkImg {
  transform: rotate(45deg);
}

.radiusVendDesIcon {
  border-radius: 50px;
  padding: 6px;
}

.alert-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 200 !important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    // width: 50%;
    min-height: 30%;
    background-color: #fff;
    border-radius: 20px;
  }

  .customPopupDesign {
    width: 400px;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: grid;
  }
}

.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }

  .center-content_new {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 18vh;
    width: 50%;
    height: 45vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }

  .center-content-address {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 50%;
    height: 70vh;
    // display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }

  .center-content-reject {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 50%;
    height: 50vh;
    // display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }

  .center-content2 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    // justify-content: cent  er;
    padding: 0px 0px 10px 0px;
  }

  .center-content-contact2 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    // width: 70%;
    height: 85vh;
  }

  .center-content-contact3 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    display: grid;
    align-items: center;
    top: 15vh;
    // width: 70%;
  }
}

.searchVendor {
  position: absolute;
  top: 10px;
  left: 9px;
}

.searchFill {
  border: $primary 1px solid !important;
  padding: 10px 10px 10px 27px !important;
  border-radius: 10px;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 400px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 400px;
        height: 230px;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.arrowIcon {
  transform: rotate(90deg);
}

// Upload img end
// Input Box end
.mailImg {
  width: 150px;
  height: 150px;
}

.artworkImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.uploadImgLib {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.uploadImgLib {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.bannerVendor {
  background-image: url("../../img/In-the-News-02.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  // height: 50% !important;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 400px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 400px;
        height: 230px;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
    height: 230px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Upload img end
// Input Box end
.mailImg {
  width: 150px;
  height: 150px;
}

.artworkImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Custom Btn start2
.get-start-btn2 {
  width: 250px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end2

// Custom Btn start3
.get-start-btn3 {
  display: flex;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end3

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 400px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 100%;
        height: 230px;
        object-fit: cover;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px !important;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
    height: 230px !important;
    // width: 400px;
    width: 100% !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    cursor: pointer;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Upload img end
// Input Box end

// Product List Comp start
.prod-list-box-tl2 {
  .prod-list-box {
    background: $lt-blue2 !important;
    padding: 20px;
    border-radius: 20px;

    // height: 348px;
    // width: 247px;
    .prod-list-box-img {
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .btm-prod {
      h3 {
        font-size: 17px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }

      p {
        font-size: 16px;
      }

      button {
        width: 35px;
        height: 35px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: auto 0 auto auto;
        color: #fff;
        font-size: 33px;
        font-family: "f5";
        background: #07679c;
      }
    }
  }
}

.prod-list-box-tl3 {
  .prod-list-box {
    background: $lt-blue2 !important;
    padding: 20px;
    border-radius: 20px;

    // height: 348px;
    // width: 247px;
    .prod-list-box-img {
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .btm-prod {
      h3 {
        font-size: 17px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }

      p {
        font-size: 16px;
      }

      button {
        width: 35px;
        height: 35px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: auto 0 auto auto;
        color: #fff;
        font-size: 33px;
        font-family: "f5";
        background: #07679c;
      }
    }
  }
}

.custom-w-h {
  // height: 250px !important;
  width: 100% !important;
  object-fit: contain !important;
  align-self: center !important;
  justify-content: center;
  // border-radius: 50px !important;
  overflow: hidden;
}

.parag {
  font-family: "f2";
  color: $primary1;
  font-size: 15px;
}

// Product List Comp end
// Product Detaile start
.top_head_cust {
  background-color: #fdfbff;
  // background-color: red;
  border-radius: 20px;
}

.place-holde {
  ::placeholder {
    color: $primary1;
    text-align: center;
    font-size: 15px;
    font-family: "f3";
  }
}

.cp {
  cursor: pointer;
}

// Product Detaile end
.mailImg {
  width: 150px;
  height: 150px;
}

.importExcel {
  width: 400px;
  height: 400px;
}

.artworkImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

// Custom Btn start
.get-start-btn {
  width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  font-family: "f3";
  background: $primary;
}

// Custom Btn end

// Input Box start
.box {
  p {
    font-size: 19px;
    color: $primary1;
    font-family: "f2";
    margin-bottom: 5px;
  }

  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #fff;
    border-radius: 10px;
    // border: 4;
    // border-color: $primary1;
    padding: 0 20px;

    border-style: solid;
    border-width: 2px;
  }
}

// Upload img start
.upload-box {
  margin-bottom: 40px;
  width: 60%;
  justify-content: space-between;
}

.file-upload {
  display: flex;
  align-items: center;
  flex-direction: row;

  .file-icon {
    width: 400px;
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f7ff;
    border-radius: 30px;
    border: 0;

    // box-shadow:
    //   -10px -10px 30px 0px #f4f8ff,
    //   10px 10px 30px 0px rgba(199, 213, 239, 0.2),
    //   -10px -10px 10px 0px rgba(199, 213, 239, 0.4) inset,
    //   10px 10px 10px 0px rgba(255, 255, 255, 0.5) inset;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.placeholder_icon {
        width: 60px;
        height: 60px;
      }

      &.placeholder_icon1 {
        width: 400px;
        height: 230px;
      }
    }
  }

  .upload-btn {
    border-radius: 10px;
    margin-top: 0px;
    font-size: 16px;
    font-family: "f3";
    color: $primary;
    text-align: center;
    align-self: center;
    overflow: hidden;
  }

  .leble-cust {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

// Upload img end
// Input Box end

// Table Btn Size start
.num-cls-btn {
  width: 92px;
  height: 45px;
  border-radius: 10px;
  background: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: $primary;
}

.num-cls-btn2 {
  width: 60px;
  height: 40px;
  border-radius: 10px;
  background: #ffffff;
  border-style: solid;
  border-width: 2px;
  border-color: $primary;
}

// Table Btn Size end

// Toggle btn start
.row {
  display: table-row;
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.yes,
.no {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  // top: 50%;
  width: 60px;
  height: 30px;
  overflow: hidden;
}

.button.r,
.button.r .no {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.yes {
  z-index: 2;
}

.no {
  width: 100%;
  background-color: $primary1;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
.button-1 .yes:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 1px;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 30px;
  color: $primary;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 9px 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.button-1 .checkbox:checked + .yes:before {
  content: "";
  left: 30px;
  border-style: solid;
  border-width: 1px;
}

.button-1 .checkbox:checked ~ .no {
  // background-color: $primary1;
  background-color: $primary;
}

.button-1 .yes,
.button-1 .yes:before,
.button-1 .no {
  transition: 0.3s ease all;
}

// Toggle btn end

// Carocle start

// Carocle end

.ani_viewope {
  display: block;
  transition: 0.5s all linear;
  opacity: 1;
}

.ani_viewclose {
  display: none;
  opacity: 0;
  transition: 0.5s all linear;
}

.uploadImgLib {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.uploadImgLib {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.bannerVendor {
  background-image: url("../../img/In-the-News-02.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  // height: 50% !important;
}

.drop-down {
  position: relative;
}

.submenu_cont_1 {
  transition: ease 0.5s;
  display: none;
}

.submenu_1 {
  display: block;
  position: absolute;
  background: #fff;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $primary;
  // top: 0px;
  height: 260px;
  transition: ease 0.5s;
}

.submenu_2 {
  display: block;
  position: absolute;
  background: #fff;
  width: 256px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: #07679c;
  height: 377px;
  transition: ease 0.5s;
  right: 0;
  left: -11px;
}

.submenu_child_2 {
  position: absolute;
  background: #fff;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $primary;
  height: 260px;
  overflow: hidden;
  // top: 80px;
  left: 200px;
  z-index: 100;
  display: none;
  transition: ease 0.7s;
}

.submenu_child_1 {
  display: block;
  position: absolute;
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $primary;
  left: 290px;
  z-index: 100;
  transition: ease 0.7s;
}

.submenu_1 .hover-cust:hover {
  background-color: $soft-gray1;
}

.submenu_2 .hover-cust:hover {
  background-color: $soft-gray1;
}

.drop_down {
  position: absolute;
  top: 9px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  right: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.drop_down2 {
  position: absolute;
  top: 9px;
  // width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  right: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.drop_down3 {
  position: absolute;
  top: 9px;
  // width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  right: 62px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.drop_down4 {
  position: absolute;
  top: 10px;
  // width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  left: 10px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  // background-color: red !important;
}

.drop_down5 {
  position: absolute;
  top: 10px;
  // width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  left: 10px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  // background-color: red !important;
}

.p-cust {
  padding-right: 35px !important;
}

.p-cust2 {
  padding-left: 35px !important;
}

// drop Down End
.uploadImgLib {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.uploadImgLib {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.bannerVendor {
  background-image: url("../../img/In-the-News-02.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  // height: 50% !important;
}

.cust-tab-style {
  background-color: $primary !important;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  transition: all ease 0.2s;
}

.cust-tab-style2 {
  background-color: $primary !important;
  transition: all ease 0.2s;
  border-radius: 5px;
}

.tranc {
  transition: all ease 0.2s;
}

.rk {
  border-style: solid;
  border-color: $primary;
  border-bottom-width: 5px;
  border-top-width: 0px;
  border-right: 0px;
  border-left: 0px;
}

// btn end

.actionPopup {
  position: absolute;
  bottom: -33px;
  right: -15px;
  background-color: white;
}

.actionDetailPopup {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
}

// Menu Background start
.invis {
  visibility: hidden;
}

.vis {
  visibility: visible;
}

.invisible-cont2 {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba(255, 0, 0, 0.094);
}

.invisible-cont3 {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 3 !important;
  // background-color: rgba(255, 0, 0, 0.094);
}

// Menu Background end

.actionPopup {
  position: absolute;
  bottom: -33px;
  right: -15px;
  background-color: white;
}

.actionDetailPopup {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
}

// Menu Background start
.invis {
  visibility: hidden;
}

.vis {
  visibility: visible;
}

.invisible-cont2 {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: rgba(255, 0, 0, 0.094);
}

// Menu Background end

.actionPopup {
  position: absolute;
  bottom: -70px;
  right: -15px;
  background-color: white;

  button:hover {
    color: #000 !important;
  }
}

.actionDetailPopup {
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: white;
}

.addScrollPop {
  height: 100%;
}

// image List slide
.product-image {
  width: 400px;
  position: relative;
  margin-bottom: 10px;
  box-shadow: inset 0 0 100px #000;
  -webkit-box-shadow: inset 0 0 100px #000;
  -moz-box-shadow: inset 0 0 100px #000;
}

.edit-image {
  top: 0;
  z-index: 999;
}

.edit-image li,
.image-controls li {
  height: 100%;
  display: block;
  width: 33%;
  float: left;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

li {
  list-style: none;
}

.product-image img {
  width: 100%;
  display: block;
}

.image-controls {
  bottom: 0;
}

.edit-image,
.image-controls {
  position: absolute;
  left: 0;
  height: 30px;
  width: 100%;
  background: $primary1;
  // border: 1px solid;
}

.edit-image li,
.image-controls li {
  height: 100%;
  display: block;
  width: 100%;
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.product-image {
  list-style: none;
  text-align: center;
  transition: all ease 0.5s;
}

.image-pro {
  width: 80px !important;
  height: 80px !important;
  cursor: pointer;
}

.image-show {
  opacity: 0;
  transition: all ease 0.5s;
}

.image-hide {
  opacity: 1;
  transition: all ease 0.5s;
}

// slide image
.slide_image {
  // width: 500px;
  width: 350px;
  height: 350px;
  // background-color: red;
  border-radius: 20px;
  border-color: $primary;
  border-style: solid;
  border-width: 2px;
}

.imagr_box {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 20px;
}

.add_img {
  width: 70px;
  height: 70px;
  background-color: $lt-blue1;
  border-radius: 10px;
}

.add_img2 {
  width: 70px;
  height: 70px;
  background-color: $lt-blue1;
  border-radius: 10px;
}

.border_img {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

// slide image
// image List slide
// .carousel-indicators [data-bs-target] {
//   box-sizing: content-box;
//   flex: 0 1 auto;
//   width: 30px;
//   height: 30px;
//   padding: 0;
//   margin-right: 3px;
//   margin-left: 3px;
//   text-indent: -999px;
//   cursor: pointer;
//   background-clip: padding-box;
//   border: 0;
//   border-top: 10px solid transparent;
//   border-bottom: 10px solid transparent;
//   opacity: .5;
//   transition: opacity .6s ease;

// }
.carousel-control-next-icon,
.carousel-control-prev-icon {
  color: #0090e7 !important;
}

.carousel-control-next,
.carousel-control-prev {
  color: #0090e7 !important;
}

.MouseOver:hover {
  opacity: 0.5;
  cursor: pointer;
}

.img {
  .image-gallery-right-nav .image-gallery-svg {
    height: 0;
    width: 0;
  }

  .image-gallery-left-nav .image-gallery-svg {
    height: 0;
    width: 0;
  }

  .image-gallery-play-button .image-gallery-svg {
    height: 0;
    width: 0;
  }

  .image-gallery-fullscreen-button .image-gallery-svg {
    height: 0;
    width: 0;
  }
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  object-position: center;
  border-radius: 20px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
  border-radius: 7px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}

// .list:hover{
//   opacity: 1 !important;
//   cursor: pointer;
//   background-color:  rgba(0, 0, 0, 0.274);
// }
.list:hover .hidden {
  background-color: rgba(246, 245, 245, 0.881);
  opacity: 11 !important;
  cursor: pointer;
  height: 100%;
  width: 100%;
  transition: 0.6s;
  border-radius: 6px;
}

.hidden {
  opacity: 0;
}

.image-gallery-svg {
  height: 75px !important;
  width: 60px;
}

.feedimg {
  height: 200px;
  width: 200px;
  object-fit: cover;
  object-position: center;
}

//rubesh-start
.miw-100px {
  min-width: 100px;
}

.w-100px {
  width: 100px;
}

.w-130px {
  width: 130px;
}

.w-200px {
  width: 200px;
}

//rubesh-end

// ADD CATEGORY

.arrow {
  width: 18px !important;
  height: 18px !important;
}

.categoryimg {
  width: 50px;
  height: 40px;
  object-fit: contain;
}

.add-category {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .sub-category {
    width: 48%;
    margin-top: 20px;

    .list {
      height: 50px;
      display: flex;
      align-items: center;
      border: 1px solid #939393;
      border-radius: 5px;
      margin: 8px 0;
      justify-content: space-between;
      padding: 0 12px;

      div {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 70%;

        p {
          width: 80%;
        }

        &:last-of-type {
          width: 30%;
          justify-content: space-around;
        }
      }
    }
  }
}

.add-category-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 150 !important;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    width: 50%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }
}

.add-category-popup2 {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 150 !important;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    width: 70% !important;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  .posi_btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.add-content {
  height: 40%;
}

// DECORATIVE METHOD
.decorative-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.decor-list {
  align-items: center;
}

// view-customer-details
.order-id-link {
  border: #07679c 1px solid;
  display: inline-block;
  border-radius: 5px;
  padding: 3px 10px;
  cursor: pointer;
  background-color: #01a5ff1f;
  margin-right: 7px;
  margin-bottom: 7px;
}

.addbtn2 {
  padding: 9px 25px 9px 25px !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-color: $primary1 !important;
}

.img_size {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.img_size2 {
  width: 90px;
  height: 90px;
  object-fit: contain;
}

.product_img {
  height: 400px;
  width: 400px;
  object-fit: contain;
}

.request-text {
  color: rgb(0, 57, 246);
}

.Inprocess-text {
  color: orange;
}

.Accept-text {
  color: green;
}

.Regected-text {
  color: red;
}

.add_icon {
  position: absolute;
  // top: 10px;
  // width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: end;
  overflow: hidden;
  // right: 10px;
  top: 0;
  right: 10px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;
}

.img_up_cont {
  background-color: #f3f7ff;
  height: 400px;
  width: 400px;
  border-radius: 20px;
  // display: flex;
}

.img_up_cont4 {
  background-color: #fff;
  height: 400px;
  width: 400px;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  border-color: $primary;
  // display: flex;
}

.img_up_cont2 {
  background-color: #f3f7ff;
  height: 350px;
  width: 90%;
  border-radius: 20px;
  // display: flex;
}

.img_up_cont3 {
  // background-color: #f3f7ff;
  height: 200px;
  width: 90%;
  // border-radius: 20px;
  // display: flex;
}

// BALA

.form-check-input:focus {
  border-color: #30c792;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(3, 241, 98, 0.25);
}

.form-check-input:checked {
  background-color: #30c792;
  border: #30c792;
}

.customer_list_slide {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 !important;
  }

  .slide_btn {
    border: none;
    background-color: rgb(219, 0, 87);
    color: white;
    width: 7.3rem;
    height: 2.5rem;
    border-radius: 5px;
  }

  .slide_btns {
    border: none;
    background-color: #01a0b6;
    color: white;
    width: 4.3rem;
    height: 2.5rem;
    border-radius: 5px;
  }

  .slide_tabs {
    margin-top: 20px;

    .border-slide_tab {
      background-color: #ffffff;
      color: rgb(123, 123, 123);
      margin-left: 1rem !important;
    }

    .border-slide_tab_line {
      border: 2px solid $primary;
    }
  }

  .slide_head {
    h5 {
      font-weight: bold;
    }

    p {
    }
  }

  .details_tab {
    label {
      margin-bottom: 5px;
      font-weight: 900;
      font-size: 14px;
    }

    p {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 500;
    }

    .slide_btns {
      border: none;
      background-color: #01a0b6;
      color: white;
      width: 6.3rem;
      height: 2.5rem;
      border-radius: 5px;
    }
  }

  .link_projects {
    p {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .files_tab {
    border: 2px solid #839dd1;
    border-style: dotted;
    width: 100%;
    height: 3rem;
  }

  .files_img {
    border: 2px solid white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 200px;
    position: relative;

    .files_btn {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;

      button {
        border: none;
        background-color: #01a0b6;
        color: white;
        font-size: 12px;
        font-weight: 500;
        width: 3.3rem;
        height: 1.5rem;
        border-radius: 5px;
      }
    }
  }

  .files_img:hover .files_btn {
    opacity: 1;
  }

  .action_btn {
    margin-top: 5px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // height: 10vh;
    width: 100%;
    position: absolute;
    overflow-y: scroll;

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 !important;
    }
  }
}

// PROJECT DETAIL POPUP
.project-detail-popup {
  width: 90% !important;
  height: 80%;
}

.overview_pages {
  .overview_page {
    .btn_overview {
      border: none;
      background-color: #fff;
      font-weight: 500;
      border-top: 0;
      color: gray;
      border-left: 0;
      border-right: 0;
      width: 4.4rem;
      font-weight: 900;
      height: 3.5rem;
    }

    .btn_overview_line {
      color: #000;
      border-bottom: 4px solid $primary;
    }

    .overview_up_btn {
      border: none;
      background-color: #01a0b6;
      color: white;
      width: 4.3rem;
      height: 2.5rem;
      border-radius: 5px;
    }
  }

  .over_view_tab {
    margin-top: 20px;

    .over_imgs {
      .over_img {
        border: 2px solid white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
        // width: 200px;
        position: relative;
      }

      .files_btn {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;

        button {
          border: none;
          background-color: #01a0b6;
          color: white;
          font-size: 12px;
          font-weight: 500;
          width: 3.3rem;
          height: 1.5rem;
          border-radius: 5px;
        }
      }

      .over_img:hover .files_btn {
        opacity: 1;
      }

      .im1 {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
      }

      .im2 {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .over_acti {
      .acti_box {
        padding: 1rem;
        padding-bottom: 0;
        margin-bottom: 2rem;
        background: #edf2f4;
      }

      button {
        border: none;
        background-color: #01a0b6;
        margin-right: 10px;
        margin-bottom: 20px;
        color: #fff;
        font-size: 14px;
        width: 10.1rem;
        height: 2.5rem;
      }
    }
  }

  .over_pro_st {
    button {
      margin-top: 2rem;
      margin-left: 2rem;
      border: none;
      background-color: #01a0b6;
      width: 4.3rem;
      height: 2.3rem;
      border-radius: 11px 1px 11px 1px;
      color: #fff;
    }
  }

  .over_p_tag {
    margin-top: 2rem;
    margin-left: 2rem;
    font-weight: 700;
  }

  .over_open {
    margin-left: 2rem;
  }
}

// PROJECT DETAIL POPUP
.project-detail-popup {
  width: 90% !important;
  height: 80%;
}

.overview_pages {
  .overview_page {
    .btn_overview {
      border: none;
      background-color: #fff;
      font-weight: 500;
      border-top: 0;
      color: gray;
      border-left: 0;
      border-right: 0;
      width: 4.4rem;
      font-weight: 900;
      height: 3.5rem;
    }

    .btn_overview_line {
      color: #000;
      border-bottom: 4px solid $primary;
    }

    .overview_up_btn {
      border: none;
      background-color: #01a0b6;
      color: white;
      width: 4.3rem;
      height: 2.5rem;
      border-radius: 5px;
    }
  }

  .over_view_tab {
    // margin-top: 20px;
    .over_imgs {
      .over_img {
        border: 2px solid white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
        // width: 300px;
        position: relative;
      }

      .files_btn {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;

        button {
          border: none;
          background-color: $primary;
          color: white;
          font-size: 12px;
          font-weight: 500;
          -webkit-box-shadow: inset -10px -10px 8px -1px #07679c,
            inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
          // padding: 9px 25px 9px 25px !important;
          // width: 3.3rem;
          // height: 1.5rem;
          border-radius: 5px;
        }
      }

      .over_img:hover .files_btn {
        opacity: 1;
      }

      .im1 {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 700;
      }

      .im2 {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .over_acti {
      .acti_box {
        padding: 1rem;
        // padding-bottom: 0;
        margin-bottom: 2rem;
        background: #edf2f4;
        border-radius: 5px;
      }

      button {
        border: none;
        background-color: #01a0b6;
        margin-right: 10px;
        margin-bottom: 20px;
        color: #fff;
        font-size: 14px;
        width: 10.1rem;
        height: 2.5rem;
      }
    }
  }

  .over_pro_st {
    button {
      margin-top: 2rem;
      margin-left: 2rem;
      border: none;
      background-color: #01a0b6;
      width: 4.3rem;
      height: 2.3rem;
      border-radius: 11px 1px 11px 1px;
      color: #fff;
    }
  }

  .over_p_tag {
    margin-top: 2rem;
    margin-left: 2rem;
    font-weight: 700;
  }

  .over_open {
    margin-left: 2rem;
  }

  .over_budget {
    border: #07679c 1px solid;
    width: 40px;
    border-radius: 0px 5px 5px 0px;
    border-left: none;

    svg {
      text-align: center;
      width: 20px;
    }
  }

  .over_budgets {
    border: #07679c 1px solid;
    width: 40px;
    border-radius: 5px 0px 0px 5px;
    border-right: none;

    .over_budget_icon {
      text-align: center;
      margin-left: 15%;
    }
  }

  .chnge_btn {
    border: none;
    background-color: #01a0b6;
    width: 3.8rem;
    height: 1.7rem;
    font-size: 12px;
    border-radius: 5px;
    color: #fff;
  }

  .author {
    font-weight: bold;
    font-size: 1.0625rem;

    .author_name {
      font-weight: normal;
      color: hsl(201.82deg 4.64% 53.53%);
      font-size: 14px;
    }
  }

  .text_auth {
    font-size: 14px;
    font-weight: normal;
  }

  .icon_com_pin {
    .hours_ago {
      color: hsl(201.82deg 4.64% 53.53%);
      font-size: 13px;
    }

    svg {
      // margin-left: 20px;
      // width: 3%;
    }
  }

  .action_overview {
    .action_btn {
      margin-top: 5px;
      text-align: start;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 15%;
      position: fixed;
      overflow-y: scroll;
      top: 105px !important;
      right: 30px !important;
      background: #fff;
      z-index: 10000 !important;

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 !important;
      }
    }
  }
}

.add-files-overiew-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 10000 !important;
  justify-content: center;

  > div {
    padding: 30px;
    width: 80%;
    height: 90vh;
    background-color: #fff;
    border-radius: 20px;

    // overflow-y: scroll !important;
    .layer_cont {
      // height: 100%;
      overflow: scroll;
    }
  }

  .over_files_pop {
    button {
      border: none;
      background-color: #fff;
      font-weight: 500;
      border-top: 0;
      color: gray;
      border-left: 0;
      border-right: 0;
      width: 7.4rem;
      font-weight: 900;
      height: 3.5rem;
    }

    .btn_overview_line {
      color: #000;
      border-bottom: 4px solid $primary;
    }
  }

  .files_pop_pro {
    margin-top: 3%;

    .over_img {
      border: 2px solid white;
      border-radius: 6px;
      transition: 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 180px;
      height: 180px;
      position: relative;

      img {
        width: 20%;
      }

      .files_btn {
        // width: 100%;
        position: absolute;
        top: 0;
        right: 0 !important;
        opacity: 0;

        .RemoveRedEyeIcon {
          color: #07679c;
        }

        // .files_btnn {
        //   width: 100%;
        //   position: absolute;
        //   top: 0;
        //   // right: 0;
        //   opacity: 1;

        button {
          border: none;
          background-color: #07679c;
          color: white;
          // font-size: 12px;
          // font-weight: 500;
          // width: 3.3rem;
          // height: 1.5rem;
          border-radius: 5px;
        }
      }
    }

    .over_img:hover .files_btn {
      opacity: 1;
    }
  }
}

.add-files-overiew-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 10000 !important;
  justify-content: center;

  > div {
    padding: 30px;
    width: 80%;
    height: 90vh;
    background-color: #fff;
    border-radius: 20px;

    // overflow-y: scroll !important;
    .layer_cont {
      // height: 100%;
      overflow: scroll;
    }
  }

  .over_files_pop {
    button {
      border: none;
      background-color: #fff;
      font-weight: 500;
      border-top: 0;
      color: gray;
      border-left: 0;
      border-right: 0;
      width: 7.4rem;
      font-weight: 900;
      height: 3.5rem;
    }

    .btn_overview_line {
      color: #000;
      border-bottom: 4px solid $primary;
    }
  }

  .files_pop_pro {
    margin-top: 3%;

    .over_img {
      border: 2px solid white;
      border-radius: 6px;
      transition: 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 180px;
      height: 180px;
      position: relative;

      img {
        width: 20%;
      }

      .files_btn {
        // width: 100%;
        position: absolute;
        top: 0;
        right: 0 !important;
        opacity: 0;

        .RemoveRedEyeIcon {
          color: #07679c;
        }

        // .files_btnn {
        //   width: 100%;
        //   position: absolute;
        //   top: 0;
        //   // right: 0;
        //   opacity: 1;

        button {
          border: none;
          background-color: #07679c;
          color: white;
          // font-size: 12px;
          // font-weight: 500;
          // width: 3.3rem;
          // height: 1.5rem;
          border-radius: 5px;
        }
      }
    }
  }

  .over_img:hover .files_btn {
    opacity: 1;
  }
}

// }
.add-files-overiew-popup
  .files_pop_pro
  .over_img
  .files_btnn
  .files_view_icon
  .a {
  color: #07679c !important;
}

.add-files-overiew-popup
  .files_pop_pro
  .over_img
  .files_btnn
  .files_view_icon
  .MuiSvgIcon {
  color: #07679c;
}

.add-files-overiew-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 10000 !important;
  justify-content: center;

  > div {
    padding: 30px;
    width: 80%;
    height: 90vh;
    background-color: #fff;
    border-radius: 20px;

    // overflow-y: scroll !important;
    .layer_scroll {
      // height: 100% !important;
      overflow: scroll !important;
    }
  }

  .over_files_pop {
    button {
      border: none;
      background-color: #fff;
      font-weight: 500;
      border-top: 0;
      color: gray;
      border-left: 0;
      border-right: 0;
      width: 7.4rem;
      font-weight: 900;
      height: 3.5rem;
    }

    .btn_overview_line {
      color: #000;
      border-bottom: 4px solid $primary;
    }
  }

  .files_pop_pro {
    margin-top: 3%;

    .over_img {
      border: 2px solid white;
      border-radius: 6px;
      transition: 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 180px;
      height: 180px;
      position: relative;

      img {
        width: 20%;
      }

      // .files_btn {
      //   // width: 100%;
      //   position: absolute;
      //   top: 0;
      //   right: 0!important;
      //   opacity: 0;

      .files_btnn {
        width: 100%;
        position: absolute;
        top: 0;
        // right: 0;
        opacity: 1;

        button {
          border: none;
          background-color: #07679c;
          color: white;
          // font-size: 12px;
          // font-weight: 500;
          // width: 3.3rem;
          // height: 1.5rem;
          border-radius: 5px;
        }
      }
    }
  }

  .over_img:hover .files_btn {
    opacity: 1;
  }
}

// }

.collaborates {
  .collaborate_page {
    .acti_box {
      padding: 1rem;
      padding-bottom: 0;
      margin-bottom: 2rem;
      background: #edf2f4;
    }

    button {
      border: none;
      background-color: #01a0b6;
      margin-right: 10px;
      margin-bottom: 20px;
      color: #fff;
      font-size: 14px;
      width: 10.1rem;
      height: 2.5rem;
    }
  }

  .collab_tip {
    margin-left: 10%;
    margin-top: 15%;
    background: #fff3dc;
    padding: 20px;
    border: 0 !important;

    h5 {
      font-size: 1.5625rem;
      font-weight: normal;
      font-style: normal;
      color: inherit;
      text-rendering: optimizeLegibility;
    }

    p {
      margin-top: 5%;
      font-size: inherit;
      line-height: 1.6;
      margin-bottom: 1rem;
      text-rendering: optimizeLegibility;
    }
  }
}

.presentaion_pages {
  .collapse-btn {
    background-color: transparent;
    border: 1px solid #06679c;
    color: #06679c;
    min-width: 125px;
  }

  .cli_present {
    margin-left: 4px;
    line-height: 0.65;
    vertical-align: top;
    padding: 14px;
    color: #06679c;
    border-color: #06679c;
    background-color: transparent;
    border: 1px solid #06679c;
    margin-top: 2%;
  }

  .action_overview {
    .action_btn {
      margin-top: 5px;
      text-align: start;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 15%;
      position: fixed;
      overflow-y: scroll;
      top: 105px !important;
      right: 30px !important;
      background: #fff;
      z-index: 10000 !important;

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 !important;
      }
    }
  }
}

.add-client-contact-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 200 !important;
  justify-content: center;

  > div {
    padding: 30px;
    width: 50%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  .addArt {
    padding: 30px;
    width: 80%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  .field_row {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;

    .art-placeholder {
      border: 2px dashed #5ca3b6;
      height: 200px;
      line-height: 200px;
      text-align: center;
      color: #5ca3b6;
      height: 100%;
      cursor: pointer;
    }
  }

  .cont-btn {
    border: none;
    border-radius: 5px;
    width: 5.3rem;
    height: 2.6rem;
    background-color: #ec5840;
    color: #fff;
  }

  .sav-btn-con {
    background-color: #06679c;
  }

  .popUpForm {
    overflow-y: auto;
    overflow-x: scroll;
    height: 90%;
  }
}

.add-client-contact-popup2 {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 100000 !important;
  justify-content: center;

  > div {
    padding: 30px;
    width: 50%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  .addArt {
    padding: 30px;
    width: 80%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  .field_row {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;

    .art-placeholder {
      border: 2px dashed #5ca3b6;
      height: 200px;
      line-height: 200px;
      text-align: center;
      color: #5ca3b6;
      height: 100%;
      cursor: pointer;
    }
  }

  .cont-btn {
    border: none;
    border-radius: 5px;
    width: 5.3rem;
    height: 2.6rem;
    background-color: #ec5840;
    color: #fff;
  }

  .sav-btn-con {
    background-color: #06679c;
  }

  .popUpForm {
    overflow-y: auto;
    overflow-x: scroll;
    height: 90%;
  }
}

.presen-list {
  .presen-detail {
    background: #edf2f5;
    border: 1px solid #edf2f5;
    border-radius: 0;
    color: #5ca3b6;
    font-size: 14px;
    // width: 7.5rem;
    height: 2.3rem;
  }

  .active {
    background: white;
    font-weight: bold;
    color: black;
  }

  .border-slide_tab {
    background-color: #ffffff;
    color: rgb(123, 123, 123);
    margin-left: 1rem !important;
  }

  .border-slide_tab_line {
    border: 2px solid $primary;
  }

  .presen-tables {
    .presen-table {
      background: #f8f8f8;
      color: #2a383f;
      height: 3.5rem;

      .drop_colo {
        background-color: #06679c;
      }
    }

    .pres-body {
      border: 1px solid #f8f8f8;
    }

    .color-for-hecd {
      position: absolute;

      button {
        border: #07679c;
        color: #fff;
        background-color: #07679c;
        margin-left: 1%;
        border-radius: 5px;
        // width: 10%;
        font-size: 12px;
        opacity: 0;
      }

      .del-colo-d {
        border: red;
        background-color: red;
      }
    }

    // .color-for-hecd:hover {
    //   background-color: #edf2f5;
    // }

    .pres-body:hover {
      background-color: #edf2f5;
    }

    .color-for-hecd:hover button {
      opacity: 1;
    }

    .grid-view-presen {
      border: 2px solid white;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 10%;
    }

    .grid-view-presen:hover .product_text {
      visibility: visible;
      opacity: 1;
    }
  }

  .product_text {
    opacity: 0;
    visibility: hidden;
  }

  .art-work-tab1:hover {
    background-color: #edf2f5;
  }

  .artwork_image {
    text-align: center;
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    float: left;
  }

  .artwork_images {
    border: dashed 2px #5ca3b6;
    padding: 0.5rem;
    min-width: 4rem;
    min-height: 5rem;
    // width: 40%;
    width: 200px;
    margin-right: 3%;
    display: inline-block;
    margin-top: 1%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .b-color-add {
    font-weight: bold;
    border: 1px solid rgb(221, 221, 221);
    margin-top: 5px;
    height: 2.5rem;
  }

  .add-tit-btn {
    position: relative;
    height: 15vh;

    .add-tit-btn1 {
      margin-right: 0.1rem;
      margin-left: 0;
      margin-bottom: 0;
      background: #bf3f69;
      font-size: 0.6rem;
      border: #bf3f69;
      color: #fff;
      display: inline-block;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 3px;
      padding: 0.85em 1em;
      margin: 0 0 1rem 0;
      opacity: 0;
    }

    .add-tit-btn2 {
      margin-right: 0.1rem;
      margin-left: 0;
      margin-bottom: 0;
      background: $primary;
      font-size: 0.6rem;
      border: $primary;
      color: #fff;
      display: inline-block;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 3px;
      padding: 0.85em 1em;
      margin: 0 0 1rem 0;
      opacity: 0;
    }

    .add-tit-btn3 {
      margin-right: 0.1rem;
      margin-left: 0;
      margin-bottom: 0;
      background: #ec5840;
      font-size: 0.6rem;
      border: #ec5840;
      color: #fff;
      display: inline-block;
      text-align: center;
      line-height: 1;
      cursor: pointer;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 3px;
      padding: 0.85em 1em;
      margin: 0 0 1rem 0;
      opacity: 0;
    }
  }

  .add-tit-btn:hover {
    background-color: #edf2f5;
  }

  .add-tit-btn:hover .add-tit-btn1 {
    opacity: 1;
  }

  .add-tit-btn:hover .add-tit-btn2 {
    opacity: 1;
  }

  .add-tit-btn:hover .add-tit-btn3 {
    opacity: 1;
  }
}

.show-scrollbar::-webkit-scrollbar {
  width: 2px !important;
}

.ideation {
  background-color: #0c5a88;
}

.sales-order {
  background-color: #00d374;
}

.in-production {
  background-color: #fa297c;
}

.invoice {
  background-color: #ffd302;
}

.upload-file-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    width: 80%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  .over_files_pop {
    button {
      border: none;
      background-color: #fff;
      font-weight: 500;
      border-top: 0;
      color: gray;
      border-left: 0;
      border-right: 0;
      width: 8.4rem;
      font-weight: 900;
      height: 3.5rem;
    }

    .btn_overview_line {
      color: #000;
      border-bottom: 4px solid $primary;
    }
  }

  .fileUp-load-box {
    border: #01a0b6;
    border-style: dotted;
    height: 3.5rem;
  }
}

.delete-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 200 !important;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    // width: 50%;
    min-height: 30%;
    background-color: #fff;
    border-radius: 20px;
  }
}

.row-ful-width {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // max-width: initial;
}

.model-heade-edit-presen {
  position: fixed;
  top: 1rem;
  // right: 1rem;
  left: 0;
  z-index: 1;
}

// input[type="text"][disabled] {
//   background-color: #fff;
//   color: #000;
// }

// textarea[type="text"][disabled] {
//   background-color: #fff;
//   color: #000;
// }
// input[type="button"] {
//   background-color: #fff;
// }
.in_btn {
  height: 42px;
  width: 50px;
  padding: 0 40px;
  border-radius: 10px;
  // font-size: 40px;
  background-color: $primary;
  border: 2px solid $primary;
  color: white;
  // margin: 20px;
}

.icon_posi {
  right: 0 !important;
}

.btn_border {
  border: $primary;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  transition: 0.5s;
}

.cust_dis_h {
  height: 135px !important;
  overflow: scroll !important;
}

.presen-pricing-tab-head {
  th {
    background: hsl(0deg 0% 97.25%);
    color: #2a383f;
  }
}

.present-edit-img {
  cursor: pointer;

  .imgs {
    background-color: transparent;
  }

  img {
    width: 100%;
  }

  .imgs:hover {
    background-color: lightgray;
  }

  .click-to-edit {
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: larger;
    font-weight: 700;
  }

  .imgs:hover .click-to-edit {
    opacity: 1;
  }
}

.present-edit-img-below {
  border: dashed 2px #06679c;
  padding: 0.5rem;
  min-width: 4rem;
  min-height: 5rem;
  width: 100%;
  margin-right: 3%;
  display: inline-block;
  margin-top: 1%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.pres-img-up-col-siz {
  background: rgb(218, 233, 238);
  color: rgb(18, 57, 82);
  border-radius: 25px;
  width: fit-content;
  padding: 5px 10px;
  margin: 5px;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
}

.variation-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  // position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 30px;
    width: 80%;
    height: 80vh;
    background-color: #fff;
    border-radius: 20px;
  }

  .var-btn {
    border: none;
    background-color: #fff;
    font-weight: 500;
    border-top: 0;
    color: gray;
    border-left: 0;
    border-right: 0;
    width: 7.4rem;
    font-weight: 900;
    height: 3.5rem;
  }

  .btn_overview_line {
    color: #000;
    border-bottom: 4px solid $primary;
  }

  .vari-col-size {
    border: 1px solid #06679c;
    width: 30%;
  }
}

.image-popup {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow: visible;
  justify-content: center;
  z-index: 11111 !important;

  > div {
    padding: 30px;
    width: 80%;
    min-height: 50%;
    background-color: #fff;
    border-radius: 20px;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    // box-shadow: inset 0 0 10px $primary1;
  }

  ::-webkit-scrollbar-thumb {
    // background: $primary1;
    box-shadow: inset 0 0 10px $primary1;
    border-radius: 10px;
  }
}

// Side bar start
.submenu_one {
  display: flex;
  flex-flow: wrap;
  flex: 0 1 auto;
  justify-content: left;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding: initial;
  box-sizing: border-box;
  width: 270px !important;
}

.nav_sc {
  overflow: scroll !important;
  height: 100%;
  scroll-behavior: smooth;
}

.submenu_ch_cont {
  flex-basis: 20.1667%;
  max-width: 20.1667%;
  transition: 0.5s;
  display: initial;
  // box-shadow:
  //   rgba(189, 189, 190, 0.25) 0px 0px 0px 0px inset,
  //   rgba(164, 163, 163, 0.3) 0px 0px 36px 0px inset;
  height: 100vh;
  background-color: $lt-blue2;
}

.submenu_two {
  flex-basis: 70.8333%;
  display: initial;
  padding: initial;
  position: relative;
  left: 8px;
  flex: 1 1 0%;
  -webkit-box-flex: 1;
  box-sizing: border-box;
  height: 100vh;
  border-top-left-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -2px 0px 10px;
}

.side_br:hover {
  overflow: hidden;
  max-width: 142% !important;
  flex-basis: 153.1667% !important;
  transition: 0.5s;
}

.side_br {
  overflow: hidden;
  max-width: 142% !important;
  flex-basis: 153.1667% !important;
  transition: 0.5s;
}

.hover_eff:hover {
  background-color: $soft-gray1 !important;
  transition: 0.1s ease-in !important;
}

// Side bar end

// Top Navbar start
.top_nav {
  // height: 100% !important;
  background: white;
  position: fixed;
  transition: 0.3s ease;
  top: 0;
  right: 0;
  border-bottom: 1px solid #edf2f4;
  z-index: 103;
  width: calc(100% - 276px);
  box-shadow: rgba(189, 189, 190, 0.25) 0px 0px 0px 0px,
    rgba(164, 163, 163, 0.3) 0px 0px 36px 0px;

  // background-color: red !important;
  .btn_chat {
    border-style: solid;
    box-shadow: rgba(189, 189, 190, 0.25) 0px 0px 0px 0px,
      rgba(164, 163, 163, 0.3) 0px 0px 36px 0px;
    padding: 5px 10px;
    border-radius: 20px;
  }
}

// Top Navbar end

.popup-bg {
  width: 100%;
  height: 100vh;
  background-color: #0000004d;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200 !important;
}

.btn-padding {
  padding: 9px 25px 9px 25px !important;
}

.border_click_cust {
  border: 2px $primary solid;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  color: $primary;
}

.rounded_cust_left {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.rounded_cust_right {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

// Table start
.table_border {
  border-width: 1px;
  border-color: #edf2f5;
  border-style: solid;
}

.tab-bor {
  border-width: 1px;
  border-color: #000;
  border-style: solid;
}

.color-for-hecd:hover {
  background-color: #edf2f5;
}

.color-for-hecd:hover button {
  opacity: 1;
  transition: 0.4s ease-out;
}

.color-for-hecd {
  position: absolute;

  button {
    border: #07679c;
    color: #fff;
    background-color: #07679c;
    margin-left: 1%;
    border-radius: 5px;
    // width: 10%;
    font-size: 12px;
    opacity: 0;
    -webkit-box-shadow: inset -10px -10px 8px -1px $primary,
      inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
    // 3px 5px 3px 1px $primary;
  }

  .del-colo-d {
    border: red;
    background-color: red;
    -webkit-box-shadow: inset -10px -10px 8px -1px red,
      inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
    // 3px 5px 3px 1px $primary;
  }
}

.bor_t {
  border-bottom: 2px;
  border-style: solid;
  border-color: #edf2f5;
}

.hover-cust2 {
  width: 1157px !important;
}

.hover-cust2:hover {
  background-color: #edf2f5;
}

// Table end
.th-sticky {
  padding: 0 25px;
  // display: inline-table !important;
  border: 1px solid transparent !important;
}

.td-sticky {
  padding: 0 25px;
  // display: inline-table !important;
  border: 1px solid #edf1f3 !important;
}

.sticky-table-table {
  width: 100%;
}

.primt-popup {
  position: fixed;
  top: 0;
  width: 600px;
  height: 100vh;
  background: #e9eaec;
  padding: 20px;
  z-index: 111;
  overflow: scroll;
  transition: 0.5s all ease-in;
}

.back-popup {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;
  width: 100%;
  height: 100vh;
  background: #000;
  padding: 20px;
  z-index: 11;
  overflow: scroll;
  transition: 0.2s all linear;
}

.danger_icon {
  width: 20px;
  height: 20px;
}

.close_position {
  right: 50px;
}

.z_ind_cust {
  z-index: 300 !important;
}

.for_hover {
  position: relative;
}

.in_hover {
  position: absolute;
  opacity: 0;
  top: 0%;
}

.for_hover:hover .in_hover {
  svg {
    width: 23%;
  }

  opacity: 1;
}

// Toggle btn start
.toggle_box_cont {
  width: 400px;
  background-color: #07689c42 !important;
}

.toggle_box1 {
  background-color: #07679c !important;
  transition: 0.5s ease-in !important;
}

.empty_box {
  button {
    color: $primary !important;
  }
}

.edit_page_cust {
  border-style: solid;
  border-width: 1px;
  border-color: $primary;
}

.edit_page_cont {
  padding: 50px !important;
}

.bottom_cust_bord {
  border-bottom: 1px solid $primary;
}

.hide_res:hover .hide_res2 {
  visibility: visible !important;
  transition: 0.3s ease;
}

.doted_border {
  border-style: dotted;
  border-width: 2px;
  border-color: $primary;
  height: 100px;
  width: 300px;

  .img_style {
    height: 90px;
    background-color: $primary1;
    // width: 250px;
  }
}

.danger_sm {
  width: 20px;
  height: 20px;
}

// Toggle btn end

// Hover Text Effect start

.present-edit-img {
  cursor: pointer;

  .img_up_cont_hover {
    height: 350px;
    width: 350px;
    border-radius: 20px !important;
    background-color: #fff;
    border-style: solid;
    border-width: 2px;
    border-color: $primary;

    // .imgs {
    //   background-color: transparent;
    // }
    img {
      width: 100%;
    }
  }

  .imgs:hover .img_up_cont_hover {
    background-color: lightgray;
    border-radius: 20px !important;
  }

  .click-to-edit {
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: larger;
    font-weight: 700;
  }

  .imgs:hover {
    background-color: lightgray;
    border-radius: 20px;
  }

  .imgs:hover .click-to-edit {
    opacity: 1;
  }
}

// DropDrow start
.drop_width {
  width: 450px;
  display: block;
  position: absolute;
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $primary;
  // top: 0px;
  height: 260px;
  transition: ease 0.5s;
}

// DropDrow End
// Radio Btn start
.radio_btn {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: $primary;
}

.sm_radio {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  transition: 0.3s ease;
  background-color: $primary;
}

.hover_Ef:hover .sm_radio1 {
  background-color: $primary !important;
  opacity: 0.3 !important;
  width: 13px !important;
  height: 13px !important;
  border-radius: 50%;
  transition: 0.3s ease;
}

.bg_fun {
  background-color: #07689c49;
}

.drop_btn {
  border: #07679c;
  color: #fff;
  background-color: #07679c;
  // margin-left: 1%;
  border-radius: 5px;
  // width: 100%;
  // font-size: 12px;
}

.btn_co {
  width: 20px;
}

.upload_btn_pop {
  border-style: dashed;
  border-width: 2px;
  border-color: $primary;
  height: 200px;
}

// Radio Btn end

// Edit Image Component start
.edit_poppup_comp {
  .edit_poppup {
    height: 300px !important;
    width: 300px !important;
    border: 2px solid white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 200px;
    position: relative;

    img {
      object-fit: contain !important;
    }

    .drop-down_show {
      right: 10px;
      position: absolute !important;
      top: 10px;
      opacity: 0;
      transition: 0.3s ease-in-out;
    }
  }

  .edit_poppup:hover .drop-down_show {
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in-out;
  }
}

// Edit Image Component end
.check-btn-proof {
  width: 17px;
  height: 17px;
  border-radius: 3px;
  border: 2px solid $primary;
}

.drop_z_ind {
  z-index: 300 !important;
}

// overview Style start
.img_size3 {
  width: 300px !important;
  height: 300px !important;
}

.sm_img_size {
  height: 60px;
  width: 60px;
  border-radius: 3px;
  object-fit: contain;
}

.sm_img_size2 {
  height: 100px;
  width: 100px;
  border-radius: 3px;
  object-fit: contain;
}

.image_hover_eff:hover .down_icon {
  visibility: visible !important;
  transition: all 0.3s;
}

// overview Style end
.prev-detail {
  .img-box {
    border: 1px solid #c2c2c2;
    width: 50%;

    .img-show {
      width: 100%;
    }
  }

  .forms {
    select,
    textarea {
      border: 1px solid #c2c2c2;
    }
  }
}

.shipping {
  input {
    border: 1px solid #c2c2c2;
    background-color: #fff;
  }
}

.command-area {
  textarea {
    border: 1px solid #c2c2c2;
    background-color: #fff;
  }
}

.detailing {
  label {
    color: $primary;
  }

  input,
  select {
    border: 1px solid #c2c2c2;
    background-color: #fff;
  }
}

.chart-label {
  a {
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.chart-right {
  border-left: 1px solid $lt-blue1;
  width: calc(100% - 140px);

  .list-value {
    position: relative;
    height: 40px;
    margin-bottom: 10px;
    background-color: $primary;
    transform: 0.2s all linear;

    &:last-child {
      margin-bottom: 0;
    }

    .tooltiptext {
      visibility: hidden;
      min-width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      background-color: $primary;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      margin-top: 5px;
      padding: 3px 10px;
    }

    &:hover {
      .tooltiptext {
        visibility: visible;
      }
    }
  }
}

// dashRightView p-5 home_section trans

// Said navbar start
.trans {
  transition: 0.3s ease;
}

// Close
.close_sidebar {
  position: fixed;
  z-index: 100 !important;
  top: 0;
  left: 0;
  height: 100%;
  padding: 10px 14px;
  width: 88px;
  background-color: #e4e9f7;
  box-shadow: rgba(189, 189, 190, 0.25) 0px 0px 0px 0px inset,
    rgba(164, 163, 163, 0.3) 0px 0px 36px 0px inset;

  .menu_bar {
    // background-color: red;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nav_link {
      cursor: pointer;
    }
  }

  li {
    height: 50px;
    // background-color: red;
    // margin-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;

    .icon {
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: 0.3s ease;
      border-radius: 6px;
      width: 100%;
      margin: 10px 0px;

      .text {
        opacity: 0;
      }
    }

    p:hover {
      // background-color: $lt-blue1;
      color: $primary !important;
    }
  }

  header {
    position: relative;

    .image_text {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 50px;
        border-radius: 6px;
      }

      img {
        width: 40px;
        border-radius: 6px;
      }

      .header_text {
        display: flex;
        flex-direction: column;

        .text {
          display: none;
        }
      }
    }

    .toggle {
      position: absolute;
      top: 50%;
      right: -25px;
      // left: 0;
      transform: translateY(-50%);
      height: 30px;
      width: 30px;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Close
.sidebar {
  position: fixed;
  z-index: 100 !important;
  top: 0;
  left: 0;
  overflow: visible !important;
  height: 100%;
  padding: 10px 14px;
  width: 270px;
  background-color: #e4e9f7;
  box-shadow: rgba(189, 189, 190, 0.25) 0px 0px 0px 0px inset,
    rgba(164, 163, 163, 0.3) 0px 0px 36px 0px inset;

  .menu_bar {
    // background-color: red;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nav_link {
      cursor: pointer;
    }
  }

  li {
    height: 50px;
    // background-color: red;
    // margin-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;

    .icon {
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: 0.3s ease;
      width: 100%;
      border-radius: 6px;
      margin: 10px 0px;
    }

    p:hover {
      // background-color: $lt-blue1;
      color: $primary !important;
    }
  }

  header {
    position: relative;

    .image_text {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 150px !important;
        border-radius: 6px !important;
      }

      img {
        width: 40px;
        border-radius: 6px;
      }

      .header_text {
        display: flex;
        flex-direction: column;
      }
    }

    .toggle {
      position: absolute;
      top: 50%;
      right: -25px;
      transform: translateY(-50%);
      height: 30px;
      width: 30px;
      background-color: $primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Said navbar end

// home section start
.home_section {
  position: relative;
  padding: 40px 50px;
  left: 270px;
  // z-index: 99 !important;
  height: 100vh;
  width: calc(100% - 270px);
  // background-color: red;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: flex-end;
}

.close_sidebar ~ .home_section {
  left: 88px;
  width: calc(100% - 88px);

  // background-color: blue;
}

// home section end

.home_section2 {
  padding: 40px 50px;
  left: 270px;
  // z-index: 99 !important;
  // background-color: red;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: flex-end;
}

.close_sidebar ~ .home_section2 {
  left: 88px;
  width: calc(100% - 88px);

  // background-color: blue;
}

// project screens start
.dashRightView_project {
  height: 100vh;
  width: calc(100% - 54px);
  background-color: white;
  padding: 50px;
  overflow: scroll;
}

// project screens end

// Presentation edit Screen start
.edit_screen_cont {
  padding: 30px 30px;
}

// Table Size
.table_head {
  width: 150px;
}

.table_data {
  width: 90px;
}

// Table Size

.color_w {
  width: 100%;
}

// Presentation edit Screen end
.prev-img {
  margin: 10px;

  img {
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    border: 1px solid #000;
  }

  .icon-delete {
    position: absolute;
    right: 0;
    right: 0;
    width: 23px;
    color: #fff;
    padding: 2px;
    background: $red;
  }
}

// Buttons

.bottom_add_btn {
  -webkit-box-shadow: inset -10px -10px 8px -1px #bf3f69,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  padding: 9px 15px 9px 15px !important;
}

.bottom_show_btn {
  -webkit-box-shadow: inset -10px -10px 8px -1px #07679c,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  padding: 9px 15px 9px 15px !important;
}

.bottom_delete_btn {
  -webkit-box-shadow: inset -10px -10px 8px -1px red,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26) !important;
  // 3px 5px 3px 1px $primary;
  padding: 9px 15px 9px 15px !important;
}

.bottom_delete_btn2 {
  -webkit-box-shadow: inset -10px -10px 8px -1px #ec5840,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
  // 3px 5px 3px 1px $primary;
  // padding: 9px 15px 9px 15px !important;
}

// Table start

.menu-list h1 {
  font-family: "f2";
  font-size: 25px;
  color: #25aae1;
  margin-bottom: 15px;
}

.table-responsive {
  overflow-x: auto;
}

.menu-table table {
  border: 1px solid #ffffff;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  /* table-layout: fixed; */
  // margin-bottom: 30px;
}

.menu-table table tr {
  // background: #efefef;
  // border: 1px solid #efefef;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.value_tr {
  td {
    width: 500px !important;
  }
}

.menu-table .btn-success {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-family: "f1";
}

.menu-table table th {
  font-size: 15px;
  color: $primary;
  font-family: "f3";
}

.menu-table img {
  width: 100px;
}

.menu-table table th,
.menu-table table td {
  // padding: 15px 10px;
  font-family: "f2";
  text-align: center;
  // color: #000;

  p {
    font-family: "f2";
    color: #000;
    text-align: center;
  }
}

.add-food-section {
  background: #191c24;
  border-radius: 10px;
  padding: 20px;
}

.add-food-section .form-control {
  background: #2a3038 !important;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "f2";
  margin-bottom: 20px;
  color: #fff !important;
}

.add-food-section label {
  font-family: "f3";
  font-size: 18px;
  margin-bottom: 15px;
}

.add-food-section input::placeholder {
  color: #6c7293;
}

.add-food-section .btn-submit {
  background: #0090e7 !important;
  padding: 10px 15px;
  color: #fff;
  font-family: "f3";
  border: 0;
  border-radius: 5px;
}

// Table end

.btn_post {
  border: #07679c;
  color: #fff;
  background-color: #07679c;
  margin-left: 1%;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0;
  -webkit-box-shadow: inset -10px -10px 8px -1px #07679c,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
}

.art-work-tab1:hover {
  background-color: #edf2f5;
}

.artwork_image {
  text-align: center;
  width: 80px;
  height: 80px;
  margin-right: 1rem;
  float: left;
}

.artwork_images {
  border: dashed 2px #5ca3b6;
  padding: 0.5rem;
  min-width: 4rem;
  min-height: 5rem;
  // width: 40%;
  width: 200px;
  margin-right: 3%;
  display: inline-block;
  margin-top: 1%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

input[type="text"][disabled] {
  background-color: #fff;
  color: #000;
}

.paid {
  background-color: rgb(0, 255, 0) !important;
  -webkit-box-shadow: inset -10px -10px 8px -1px rgb(0, 255, 0),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26) !important;
}

z .pending {
  background-color: rgb(255, 81, 0) !important;
  -webkit-box-shadow: inset -10px -10px 8px -1px rgb(255, 81, 0),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26) !important;
}

.proces {
  background-color: rgb(255, 196, 0) !important;
  -webkit-box-shadow: inset -10px -10px 8px -1px rgb(255, 196, 0),
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26) !important;
}

:root {
  --primary-color: steelBlue;
}

.prod-check-box {
  .tick-mark1 {
    width: 21px;
    height: 21px;
    background: $primary;
    border-radius: 2px;

    .tick-mark {
      position: relative;
      transition: 0.2s all linear;
    }

    .tick-mark::before {
      position: absolute;
      left: 0;
      top: 50%;
      height: 50%;
      width: 3px;
      background-color: #fff;
      content: "";
      transform: translateX(7px) rotate(-45deg);
      transform-origin: left bottom;
      transition: 0.2s all ease-in;
    }

    .tick-mark::after {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 100%;
      background-color: #fff;
      content: "";
      transform: translateX(7px) rotate(-45deg);
      transform-origin: left bottom;
      transition: 0.2s all ease-in;
    }
  }
}

.sm-btn {
  border: #07679c;
  color: #fff;
  background-color: #07679c;
  // margin-left: 1%;
  border-radius: 5px;
  font-size: 12px;
  opacity: 1;
  -webkit-box-shadow: inset -10px -10px 8px -1px #07679c,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26);
}

// Image view Hover

.hover_eff2:hover button {
  visibility: visible !important;
}

// Text  Color

.paid_text {
  color: #32cd32 !important;
  font-size: 20px;
}

.proces_text {
  color: orange !important;
  font-size: 20px;
}

.reject_text {
  color: red !important;
  font-size: 20px;
}

.table-design-new {
  th {
    font-family: "f1" !important;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    padding: 10px;
  }
}

// Status Btns
.status_btn {
  .accepted_btn {
    background-color: #008001;

    .text_btn {
      color: #fff;
      font-weight: 800;
    }
  }

  .reject_btn {
    background-color: #f86c6b;

    .text_btn {
      color: #fff;
      font-weight: 500;
    }
  }

  .process_btn {
    background-color: #d0c527;

    .text_btn {
      color: #fff;
      font-weight: 800;
    }
  }

  .pending_btn {
    background-color: #d08127;

    .text_btn {
      color: #fff;
      font-weight: 800;
    }
  }
}

// Input box icon

.box_icon {
  position: absolute;
  top: 238px;
  left: 49px;
  height: 40px;
  width: 40px;
  background-color: $lt-blue1;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  // border-start-end-radius: 10px;
}

.cust_table {
  table {
    width: 100%;

    thead {
      tr {
        th {
          // background-color: red;
          white-space: nowrap !important;
          padding-right: 30px !important;
          padding-left: 30px !important;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        td {
          // background-color: red;
          white-space: nowrap !important;
          padding-right: 30px !important;
          padding-left: 30px !important;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

.cust_table2 {
  table {
    width: 100%;

    thead {
      tr {
        th {
          // background-color: red;
          white-space: nowrap !important;
          padding-right: 30px !important;
          padding-left: 30px !important;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        td {
          // background-color: red;
          white-space: nowrap !important;
          padding-right: 30px !important;
          padding-left: 30px !important;
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }
}

// Line
.line {
  height: 0.3px;
  background-color: #dddddd;
}

.bg_today {
  -webkit-box-shadow: inset -2px 2px 4px 3px #fff,
    10px 10px 13px -5px rgba(161, 183, 219, 0.44);
}

.scroll_view {
  height: 400px;
  overflow-y: scroll;
}

.scroll_view1 {
  height: 100%;
  overflow-y: scroll;
}

.arw-svg {
  position: absolute;
  font-size: 30px;
  top: -20px;
  right: 0px;
  color: #06679c;
}

.product-transfer-view h4 {
  font-size: 16px;
  margin-bottom: 0;
  color: #000;
}

.product-transfer-view {
  width: 100%;
  background: white;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
  // border-top: 3px solid #247395;
  position: absolute;
  z-index: 1;
  top: 45px;
  right: 0px;
  padding: 10px;

  .po-sc-drop-down {
    font-size: 35px;
    font-family: "f3";
  }

  .product-transfer-view-model-tick-cont-active {
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    position: relative;

    .line1 {
      width: 2px;
      height: 40px;
      position: absolute;
      top: 30px;
      // left: 13px;
    }

    svg {
      border-radius: 100px;
      font-size: 20px;
      height: 26px;
      width: 26px;
    }
  }

  h6,
  p {
    font-size: 13px;
  }

  span {
    color: #a09f9f;
  }
}

.err-input {
  position: absolute;
  // right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: red;
}

.err-input_log {
  // position: absolute;
  // right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: red;
}

.svg_log {
  width: 18px !important;
  color: red;
}

.err-input_new {
  position: absolute;
  font-size: 12px !important;
  font-family: "f2";
  color: red;
}

// search input
.border-search {
  border: 1px solid #d3d3d3;
  padding: 4px;
  border-radius: 10px;
}

// customer request select input
.w-17 {
  @media (min-width: 768px) {
    width: 17%;
  }
}

.mt-10 {
  @media (min-width: 768px) {
    margin-top: 10px;
  }
}
.tabs {
  table {
    border: 0;
  }

  td,
  tr {
    border-color: #cacaca;
    border-style: solid;
    border-width: 1px;
  }
}
.star-rating {
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .on {
    color: #0b81acf5;
  }

  .off {
    color: #e5e5e5;
  }
}
.bar {
  height: 200px;
  // width: 300px;
}
.table_inner_border {
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  table {
    border: 0;
  }
  table td,
  table th {
    border: 1px solid #cacaca !important;
  }
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 3px solid #337ab7;
  border-radius: 12px !important;
}
.image-gallery-thumbnail,
.image-gallery-thumbnail:focus {
  border: 3px solid #337ab7;
  border-radius: 12px !important;
}
// .ryt-line{
//   border-right: 2px !important;
//               border-color:#cacaca;
//               border-style: solid;
//               border-top: 0;
//               border-bottom: 0;
//               border-left:0 ;
// }
// product management list overview form
.full-img {
  height: 500px;
  width: 100%;
}
.mail-btn {
  background-color: #009688 !important ;
  -webkit-box-shadow: inset -33px -20px 14px -6px #009688,
    inset -4px -4px 12px 1px;
}
.toggle_btn {
  position: relative;
  width: 40px;
  height: 20px;
  overflow: hidden;
  border-radius: 10px;
}

.toggle_btn .yes:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 1px;
  top: 0px;
  left: 0px;
  width: 18px;
  height: 18px;
  color: $primary;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding: 9px 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.toggle_btn .checkbox:checked + .yes:before {
  content: "";
  left: 20px;
  border-style: solid;
  border-width: 1px;
}

.toggle_btn .checkbox:checked ~ .no {
  // background-color: $primary1;
  background-color: $primary;
}

.toggle_btn .yes,
.toggle_btn .yes:before,
.toggle_btn .no {
  transition: 0.3s ease all;
}

/* PopUp */
.popup-wrap {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner_box {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;

  input {
    border: 1px solid #07679c;
  }
}

.add_event_cat_btn {
  border-radius: 10px;
  background: #07679c;
  padding: 4px 10px;
  color: #fff;
  font-size: 16px;
}

.popup_close {
  top: 4%;
  right: 4%;
  color: #07679c;
  font-weight: 700;
  background: #ffffff;
  border-radius: 30px !important;
  box-shadow: -2px -2px 5px 0px #b2c3e133 !important;
}

.popup_close:focus {
  animation-timing-function: ease-out;
  animation-duration: 300ms;
}

.table_img {
  width: 60px;
  height: 60px;
}

.table_img_container {
  margin: 0 auto;
  width: 30%;
}

.w-20 {
  width: 20%;
}

.preset_count {
  color: #00bf7c;
}

.tag_filter {
  font-size: 16px;

  button {
    color: #07679c;
    font-size: 14px;
    border: none;
    background-color: #fff;
  }
}

.priority {
  width: 110px;
  color: #fff;
  border-radius: 5px;

  .icon {
    width: 20px;
    height: 20px;
    color: #fff;
  }

  button {
    padding: 2px;
  }

  button {
    padding: 2px;
  }

  span {
    margin-top: 4px;
    font-size: 14px;
  }
}

.danger {
  background: #ff0000;
}

.success {
  background: #26d497;
}

.danger {
  background: #ff0000;
}

.success {
  background: #26d497;
}

.custom-btn {
  top: 45%;
  right: 0%;
}

.pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.down-icon {
  top: 56%;
  right: 3%;
}

// multi select tags
.css-qbdosj-Input {
  display: none !important;
}

.upload_img {
  padding: 0px !important;
}

.upload_img::file-selector-button {
  background: #acc0e9;
  width: 113px;
  height: 38px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

// navigation category
.category_list li {
  width: 219px;
  height: 60px;
  border-radius: 10px;
  border: 1px;
  margin-top: 10px;
  border: 1px solid #a7a7a7;

  .down-arrow {
    color: #839dd1;
    font-size: 30px;
    cursor: pointer !important;
  }

  .category_list_img {
    width: 80px;
    height: 40px;
  }
}

.category_list .green {
  background-color: #c1ffe9 !important;
  color: #646464;
}

.status-bar {
  background: #fdfbff;
  padding: 10px;
}

.tab_w {
  width: 100px !important;
  // sam
}

.datepicker-input::-webkit-calendar-picker-indicator {
  display: none;
}

.chatBackGround {
  background-color: #dcf1f5;
  width: auto;
  height: auto;
  border-radius: 10px;
}

.userDetails {
  height: 150px;
  width: 300px;
  background-color: white;
  border-radius: 10px;
}

.chatprofile {
  width: 100px;
  height: auto;
  border-radius: 20px;
}

.profileimgage {
  width: 50px;
  height: 50px;
  border-radius: 30px;
  margin-top: 15px;
}

.messageContent {
  width: 300px;
  height: auto;
  background-color: white;
}

.messageText {
  width: 100vh;
  border-radius: 100px;
  border-color: white;
}

.typeContent {
  width: auto;
  height: 40px;
  background-color: white;
  margin-top: 20px;
  border-color: #07679c;
}

.pulsIcon {
  width: 30px;
  height: 30px;
  background-color: #07679c;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 800;
  color: white;
  text-align: center;
  margin-left: 10px;
}

.cust-table {
  .table-design-new tbody,
  .table-design-new td,
  .table-design-new tfoot,
  .table-design-new th,
  .table-design-new thead,
  .table-design-new tr {
    padding: 20px;
  }
}

// Sanakri start
.texta {
  height: 100px;
}

.min_pop {
  height: inherit;
  width: 250px;
  position: absolute;
  background: #fff;
  right: 50px;
}

.hov_popp {
  position: absolute;
  width: 90%;
  margin-top: 5px;
  opacity: 0;
  bottom: 0px;
}

.rel_sty:hover .hov_popp {
  opacity: 1;
  background-color: #fff;
}

.hov_popp2 {
  position: absolute;
  width: 90%;
  margin-top: 5px;
  opacity: 0;
  bottom: -42px;
  right: -42px;
}

.rel_sty:hover .hov_popp2 {
  opacity: 1;
  background-color: #fff;
}

.select_drop {
  width: 128px;
}

.artwor_btn {
  background-color: #9d5e00 !important;
  border: #9d5e00 !important;
  color: #fff !important;
  margin-left: 1% !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  -webkit-box-shadow: inset -10px -10px 8px -1px #9d5e00,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26) !important;
}

.imageup_cont {
  width: 300px;
  border: dashed 2px #000;
  height: 100px;
}

.imageup_cont2 {
  width: 200px;
  border: dashed 2px #000;
  height: 200px;

  .up_img {
    height: 190px;
    width: 190px;
    object-fit: cover;
  }

  .close_up_btn {
    position: absolute;
    right: 0;
    top: -10px;
    border-radius: 100px;
    background-color: red;
    height: 30px;
    width: 30px;
  }
}

.upicon {
  height: 50px;
  width: 50px;
}

.artwork_comp {
  border: #c9cd00 !important;
  color: #fff !important;
  background-color: #c9cd00 !important;
  margin-left: 1% !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  -webkit-box-shadow: inset -10px -10px 8px -1px #c9cd00,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26) !important;
}

.artwork_aproved {
  border: #0d9d00 !important;
  color: #fff !important;
  background-color: #0d9d00 !important;
  margin-left: 1% !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  -webkit-box-shadow: inset -10px -10px 8px -1px #0d9d00,
    inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26) !important;
}

.img_pop {
  height: 100px;
  width: 100px;
}

// Sanakri end

.popup-wrap_new {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-innerbox_new {
  background-color: rgb(25, 28, 36);
  width: 38%;
  height: 30%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 16px;
    font-family: "f2";
    text-align: center;
    color: #fff;
  }

  div {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;

    button {
      margin: 0;
      border: 0;
      padding: 6px 20px;
      border-radius: 6px;
      font-size: 16px;
      font-family: "f3";
      color: #fff;

      &:first-of-type {
        background-color: #07679c !important;
      }

      &:last-of-type {
        background-color: #787b7f;
      }
    }
  }
}

.popup-pass_new {
  position: fixed !important;
  top: calc(100vh - 300px) !important;
}
