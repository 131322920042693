.tot-cont {
  color: #00bf7c;
  font-weight: 600;
}

// TOLLTIP BOOTSTARP CUSTOM DESIGN

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #000;
  --bs-tooltip-bg: #ffffff;
  box-shadow: 0 0.8rem 2rem rgba(0, 0, 0, 0.1490196);
  /* -webkit-box-shadow: 7px 7px 15px -1px #d1d6eb; */
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 1;
  border: #efefef 1px solid;
  border-radius: 20px;
}

// REJECT POPUP
.modal-popup5 {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }

  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 70%;
    height: 70vh;
    display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }

  .center-content-address {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 50%;
    height: 70vh;
    // display: grid;
    overflow: scroll;
    align-items: center;
    justify-content: center;
  }

  .center-content5 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 15vh;
    width: 35%;
    height: 35vh;
    // display: grid;
    // overflow: scroll;
    align-items: center;
    padding: 0px 0px 10px 0px;
  }

  .center-content-contact2 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 8vh;
    // width: 70%;
    height: 85vh;
  }

  .center-content-contact3 {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    display: grid;
    align-items: center;
    top: 15vh;
    // width: 70%;
  }
}

.page_new {
  position: absolute;
  background-color: black;
  padding: 0;
  width: 100%;
  height: 100%;
}

.container_new {
  margin: 2rem auto;
  width: 60%;
  height: 90%;
  border-radius: 5px;
  overflow: auto;
  padding: 15px;
}

.dnd-list_new {
  display: flex;
  flex-direction: row;
  height: 35px;
  margin-bottom: 10px;
}

.input-item_new {
  position: relative;
  height: 100%;
  flex-basis: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-bottom: 10px;
  font-size: calc(10px + 2vmin);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: move;
  padding-left: 10px;
}

.over_new {
  transform: scale(1.01);
  border: 1px dashed #eee;
}

.addButton_new {
  display: flex;
  align-items: center;
  margin: auto;
  line-height: 26px;
  border: none;
  color: black;
  border-radius: 50%;
  font-size: 24px;
}

.delButton_new {
  display: flex;
  align-items: center;
  color: darkgrey;
  margin-left: 10px;
  height: 37px;
  width: 30px;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
}

.collaborate_pages {
  .action_overview {
    .action_btn {
      margin-top: 5px;
      text-align: start;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      width: 15%;
      position: fixed;
      overflow-y: scroll;
      top: 105px !important;
      right: 30px !important;
      background: #fff;
      z-index: 10000 !important;
    }
  }
}
